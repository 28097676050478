<template>
  <div class="image-uploader">
    <InfoBlock
      class="upload-image"
      border-dashed
      color="2F2F2F"
      :border-color="errorMessages.length > 0 ? 'ff6262' : '515151'"
      data-testid="elem_u_000753"
    >
      <div
        v-if="images.length > 0"
        class="upload-image-content"
        data-testid="elem_u_000754"
      >
        <div class="upload-image-controls">
          <ImageItemControls
            v-if="!multiple && !loading"
            :uploadable="!readonly"
            :clearable="clearable"
            :accept="accept"
            :hint="hintText"
            :size="size"
            class="image-item-actions"
            vertical
            @upload="(uploadedfile) => uploadImage(uploadedfile, images[0].url)"
            @clear="clearFile(images[0].url)"
            data-testid="elem_u_000755"
          />
          <div
            class="upload-image-items"
            :class="{ 'upload-image-items__multiple': multiple }"
            data-testid="elem_u_000756"
          >
            <template v-if="!isArchive">
              <ImageItem
                v-for="image in images"
                :key="image.url"
                :url="image.url"
                :alt="image.name"
                :type="image.type"
                :loading="loading"
                :hint="hintText"
                :rounded="rounded"
                :rectangular="rectangular"
                :accept="accept"
                :size="size"
                class="image-item"
                :small="multiple"
                :controls="multiple"
                :readonly="readonly"
                :clearable="clearable"
                @upload="(uploadedfile) => uploadImage(uploadedfile, image.url)"
                @clear="clearFile(image.url)"
                data-testid="elem_u_000757"
              />
            </template>

            <div
              v-if="isArchive"
              class="archive-wrapper"
            >
              <v-img :src="require(`@/assets/img/archive.png`)"> </v-img>
            </div>

            <AddImageButton
              v-if="multiple"
              :rounded="rounded"
              :tooltip="hintText"
              :accept="accept"
              :hint="hint"
              @upload="uploadImage"
              data-testid="elem_u_000758"
            />
          </div>
        </div>

        <div
          class="file-progress"
          v-if="progress"
        >
          <div class="file-description">
            <template>
              <div
                class="file-name"
                v-if="value?.path"
              >
                {{ value.path.split('/')[1] }}
              </div>

              <div
                class="file-name"
                v-else-if="value?.name"
              >
                {{ value.name }}
              </div>
            </template>

            <div
              class="file-size"
              v-if="value?.size"
            >
              {{ (value.size / $options.BYTES_IN_MB).toFixed(digitsAfterTheDot) }} Mb
            </div>
          </div>

          <v-progress-linear
            v-if="(progress.percent > 0 && progress.percent < 100) || value.type"
            class="progress-linear"
            :value="progress.percent"
            height="2px"
            color="#8cd317"
            background-color="#515151"
            rounded
          ></v-progress-linear>
        </div>
      </div>

      <EmptyContent
        v-if="images.length === 0"
        :label="label"
        :hint="hintText"
        :accept="accept"
        :required="required"
        :size="size"
        @upload="uploadImage"
        data-testid="elem_u_003176"
      />

      <div
        v-if="disabled"
        class="disabled-wrapper"
      ></div>
    </InfoBlock>

    <FormErrorLine
      :message="errorMessages[0]"
      data-testid="elem_u_000760"
    />
  </div>
</template>

<script>
import InfoBlock from '@/components/common/InfoBlock';
import UploadDialog from '@/components/common/Uploads/UploadDialog';
import FormErrorLine from '@/components/common/Provider/EditFormBlock/FormErrorLine';

import EmptyContent from './EmptyContent';
import ImageItem from './ImageItem';
import AddImageButton from './AddImageButton';
import ImageItemControls from './ImageItemControls';

import { IMAGE_TYPES, IMAGE_TYPE_NAMES } from './image-types';

const BYTES_IN_MB = 1024 * 1024;

export default {
  IMAGE_TYPES,
  IMAGE_TYPE_NAMES,
  BYTES_IN_MB,

  components: {
    InfoBlock,
    UploadDialog,
    EmptyContent,
    ImageItem,
    AddImageButton,
    ImageItemControls,
    FormErrorLine,
  },

  props: {
    value: {
      type: File | Object,
      default() {
        return null;
      },
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },

    formats: {
      type: Array,
      default() {
        return [];
      },
    },

    size: {
      type: Number,
      default: 2 * BYTES_IN_MB,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    rectangular: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    progress: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      acrhivesTypes: ['zip', 'rar', '7z', 'compressed'],
    };
  },

  computed: {
    isArchive() {
      return this.acrhivesTypes.some((type) => {
        return this.images[0].type.split('/')[1].includes(type);
      });
    },

    images() {
      if (Array.isArray(this.value)) {
        return this.value.map((file) => ({
          url: file?.url ?? '',
          name: file.name,
          type: file.type || file.mime_type,
        }));
      } else {
        return this.value
          ? [
              {
                name: this.value.name,
                url: this.value?.url ?? '',
                type: this.value.type || this.value.mime_type,
              },
            ]
          : [];
      }
    },

    digitsAfterTheDot() {
      return this.value.size > 100000 ? 1 : 2;
    },

    accept() {
      return this.formats.length === 0 ? IMAGE_TYPES.all : this.formats.join(', ');
    },

    hintText() {
      if (this.hint !== '') {
        return this.hint;
      }

      const formatsText =
        this.formats.length === 0
          ? 'Available file all image types.'
          : `Available file types: ${this.formats
              .map((format) => {
                return IMAGE_TYPE_NAMES[format].map((lb) => `${lb}`).join(', ');
              })
              .join(', ')}.`;

      const maxSizeText = `Max file size ${this.size / BYTES_IN_MB} Mb.`;

      return [formatsText, maxSizeText].join('<br/>');
    },
  },

  methods: {
    async uploadImage({ image: file }, oldUrl = null) {
      try {
        let value = this.value;

        if (file.type.split('/')[0] === 'image') {
          const arrayBuffer = await file.arrayBuffer();
          const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });

          file.url = URL.createObjectURL(blob);
        }

        // if (file.type.split('/')[0] === 'application') {
        //   const acrhivesTypes = ['zip', 'rar', '7z'];

        //   this.isArchive = acrhivesTypes.some((type) => {
        //     return file.type.includes(type);
        //   });
        // }

        if (this.multiple && Array.isArray(this.value)) {
          if (oldUrl !== null) {
            value.splice(
              value.findIndex((image) => image.url === oldUrl),
              1,
              file,
            );
          } else {
            if (value.some((val) => val.name === file.name)) {
              this.$alert.notifyInfo('The image has already been selected!');
              return;
            }
            value.push(file);
          }
        } else {
          value = file;
        }

        this.$emit('input', value);
      } catch (e) {
        this.$alert.notify('File not uploaded', 'error');
      }
    },

    clearFile(url) {
      this.$emit(
        'drop',
        this.multiple && Array.isArray(this.value)
          ? this.value.find((file) => file.url === url)
          : this.value,
      );

      this.$emit(
        'input',
        this.multiple && Array.isArray(this.value)
          ? this.value.filter((image) => image.url !== url)
          : null,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled-wrapper {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--color-grey-500);
  opacity: 0.4;
  border-radius: 10px;
  z-index: 100;
}
</style>

<style lang="scss">
.image-uploader {
  position: relative;
}

.upload-image {
  max-width: 100%;
  scale: 1; // to reset the coordinate grid, for further application of position fixed inside the block
}

.upload-image-content {
  height: 100%;
  padding: 20px 20px 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-image-content-title {
  position: absolute;
  top: -3px;
  left: 0;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  height: 20px;
  letter-spacing: normal;
  line-height: 18px;
  color: var(--color-grey-900) 7f;
  transform: translateY(-18px) scale(0.75);
  transform-origin: top left;
  &.required {
    &::after {
      content: ' *';
      color: var(--color-warning);
    }
  }
}

.upload-image-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 250px;
  &__multiple {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.image-item-actions {
  position: absolute;
  top: 19px;
  right: 19px;
}

.file-progress {
  width: 100%;
  margin-top: 10px;
}

.file-description {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-grey-500);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.file-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file-size {
  white-space: nowrap;
}

.progress-linear {
  margin-top: 10px;
}

// a hack to fix a progressbar issue (progressbar doesn't reset after modal window closes) in some browsers
.v-progress-linear__background, .v-progress-linear__determinate {
  transition: none;
}

.archive-wrapper {
  min-height: 126px;
}
</style>
