// imports
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';
import alertModule from './plugins/alert';

import i18n from './plugins/vue-i18n';

import moment from 'moment-timezone';

import setAxiosSettings from './services/AxiosSettings';
import UnzipButton from '@/components/global/UnzipButton.vue';
import UnzipSelect from './components/common/UnzipSelect.vue';

import '@/services/moneyFormat';

import '@/assets/styles/index.scss';

// default settings
moment.tz.setDefault('America/Los_Angeles');
setAxiosSettings();
Vue.config.productionTip = false;

// plugins
Vue.use(alertModule);

// global components
Vue.component('UnzipButton', UnzipButton);
Vue.component('UnzipSelect', UnzipSelect);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
